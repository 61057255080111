import { render, staticRenderFns } from "./login.vue?vue&type=template&id=81b3f082"
import script from "./login.vue?vue&type=script&lang=ts"
export * from "./login.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../common/temp/node_modules/.pnpm/vue-loader@15.11.1_01a74382766bfa0784a6747bb7c3efbb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports